.hotel-card .content {
  width: 90% !important;
  margin: auto !important;
  margin-top: -50px !important;
  border: 1px solid #d9d9d9;
  background-color: white !important;
  padding: 20px;
  z-index: 2;
  position: relative;
}

.hotel-card .no-link {
  text-decoration: none;
  color: initial;
}

.purple {
  color: #4f0da3;
}

.hotel-mobile-nav {
  display: flex;
}
.hotel-mobile-nav button {
  width: 100%;
  padding: 15px 20px;
  border: none;
  background-color: #d9d9d9;
  color: black;
}

.hotel-mobile-nav a {
  width: 120%;
}

.hotel-mobile-nav .active {
  background-color: #ff8a15;
  color: white;
}

.hotel-mobile-nav .btn1 {
  border-bottom-left-radius: 10px;
}

.hotel-mobile-nav .btn2 {
  border-bottom-right-radius: 10px;
}

@media screen and (min-width: 600px) {
  .hotel-mobile-nav {
    display: none;
  }
}

.hotel-btn {
  background-color: #4f0da3;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.hotel-btn2 {
  background-color: white;
  color: #4f0da3;
  border: 1px solid #4f0da3;
}

.hotel-gray-btn {
  width: 211px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  background-color: white;
  color: gray;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 4px;
}

.hotel-close-btn {
  float: right;
  font-size: 20px;
}

.hotel-card .content .hover-content {
  display: none;
}

.hotel-card:hover .content {
  margin-top: -100px !important;
  transform-origin: 0.5s all;
}

.hotel-card:hover .more-content {
  margin-top: -120px !important;
}

.hotel-card:hover .content .hover-content {
  display: block;

  padding: 10px;
}

/* .promote-hotel input[type="radio"] {
    position: relative;
}

.promote-hotel input[type="radio"]:checked::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: purple;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */


.about-modal{
  width: 300px
}


@media screen and (min-width: 700px) {
  .about-modal{
    width: 500px
  }
}