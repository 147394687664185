.audio-player::-webkit-media-controls-panel {
  background-color: transparent !important;
  background: #000;
}

.audio-player::-webkit-media-controls {
  background-color: transparent !important;
  background: #000;
}

.audio-container .rhap_container {
  background-color: transparent; /* Set the player's background to transparent */
  box-shadow: none; /* Remove any default shadows */
}
