.connect-tab {
  width: 50%;
}
.conn-t-txt {
  width: 100%;
}
.connect-profile-view-box {
  /* position: relative; */
}
.cont-view-connect {
  background: rgba(0, 0, 0, 0.6);
  margin-top: -20px;
  height: 400px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  gap: 20px;
  width: 100%;
}
.image-bx-cont {
  width: 50%;
  height: 90%;
  position: relative;
  background: #302f2f91;
  border-radius: 5px;
}
.image-bx-cont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
}
.mvmm img {
  height: 70vh;
}

.arrr-ctrl {
  font-size: 15px;
  border-radius: 44px;
  background: #fff;
  width: 30px;
  height: 30px;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.main-indicator-cont {
  padding: 0 10px;
  width: 100%;
  display: flex;
  gap: 10px;
}
.disable {
  background: rgba(255, 255, 255, 0.5);
}
.indicator-bx {
  margin-left: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  position: absolute;
  top: 0;
  width: 95%;
  justify-content: center;
}
.ind-con {
  background: #ffffff70;
  width: 95px;
  height: 3px;
  border-radius: 10px;
  margin-top: 10px;
}
.actvv {
  background: #4f0da3;
}
.share-bx {
  background: #000;
  color: #fff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  right: 3%;
  top: 5%;
}
.prof-bx-connect img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.prof-bx-connect {
  color: #fff;
  position: absolute;
  font-size: 10px;
  bottom: 10%;
  left: 5%;
}
.username-txtt {
  max-width: 150px;
  margin-bottom: 2px;
}
.biiio {
  line-height: 12px;
}
.user-nmm {
  font-size: 12px;
  font-weight: 500;
}
.ch-st-btn {
  font-size: 10px;
  background: #4f0da3;
  border: none;
  outline: none;
  width: 35%;
  height: 35px;
  border-radius: 30px;
  color: #fff;
}
.chat-stick-btn-bx {
  justify-content: center;
  gap: 10px;
  position: absolute;
  border: 0;
  width: 100%;
  bottom: -2%;
}
.ora-btn {
  background: #ff8a15;
}
.all-ma-box {
  width: 100%;
  height: 100%;
  gap: 10px;
}
.loading-dots {
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    content: ".";
  }
  25% {
    content: "..";
  }
  50% {
    content: "...";
  }
  75% {
    content: "....";
  }
  100% {
    content: "";
  }
}

@media only screen and (max-width: 940px) {
  .arrr-ctrl {
    display: none;
  }
  .image-bx-cont {
    width: 100%;
    height: 98%;
  }
  .cont-view-connect {
    padding: 0 10px;
    align-items: center;
    margin: 1px;
    justify-content: flex-start;
    width: 100%;
  }
  .ch-st-btn {
    height: 25px;
  }
}
@media only screen and (max-width: 321px) {
  .conn-t-txt {
    font-size: 10px;
  }
}
