.box {
    border: 1px solid #4b0289;
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    padding: 20px;
}

.boxText{
    padding: 50px 0;
}

.icon-container {
    display: flex;
    align-items: center;
}
.icon-border {
    border-radius: 8px;
    border: 1px solid #4b0289;
    padding: 15px;
}

.icon-border svg {
    width: 28px;
    height: 28px;
    fill: #4b0289;
}

.heading-tertiary {
    margin: 0;
}

.bParagraph {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.action {
    text-decoration: underline;
    margin: 0;
    font-size: 18px;
}