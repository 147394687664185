.modal_btn {
  width: 204px;
  height: 54px;
  border-radius: 10px;
  background: #4f0da3;

  font-size: 18px;
  color: #fff;
  margin: 1rem 0;

  /* new */
  display: flex;
  justify-content: center;
  align-items: center;

  align-self: center;
}

/* PROFILE DETAILS */
.user_profile_image {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #6d6d6d9c;

  display: flex;
  flex-direction: column;
  padding: 2rem 8rem;
}

.user_profile_image .cancel_btn {
  display: flex;
  margin: 4rem 0;
}

.user_profile_image .cancel_btn .arrow_back {
  width: 25.38px;
  height: 26px;
  color: #ffff;
}

.user_image_container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user_image_container2 {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user_image_container img {
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 100%;
}

.user_image_container2 img {
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 8px;
}

.cover_image {
  width: 100%;
  height: 150px;
}

.cover_image img {
  width: 100%;
  height: 100%;
}

.profile_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -5.5rem;
  padding-bottom: 3rem;
}

.profile_details img {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  object-fit: cover;
  cursor: pointer;
}

.profile_details h1 {
  color: #000000cc;
  font-size: 16px;
  font-weight: 500;

  margin-top: 1rem;
}

.location_expertise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.profile_details span {
  color: #000000cc;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
}

.profile_details_btns {
  display: flex;
  gap: 17px;
  margin-top: 10px;
}

.stickers {
  display: flex;
  width: 125px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #4f0da3;
  transition: all 0.3s ease-in-out;
}

.stickers span {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}
.stickers span:last-child {
  font-weight: 800;
}

/* PROFILE ACTIVITIES */
.profile_activities {
  margin: 30px 0;
}

.cta-btns {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;

  display: flex;
  gap: 20px;

  border-bottom: 1px solid #e6e6e689;
  padding: 10px 0;
}

.cta-btns::-webkit-scrollbar {
  width: 0vw;
  height: 0vh;
}

/* PROFILE POSTS ACTIVITIES */
.profile_posts {
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  column-gap: 6px;
  row-gap: 10px;
}

.profile_posts_container {
  display: flex;
  justify-content: center;
}

.profile_posts_container img {
  width: 100%;
}

.profile_activities_bottom_ads img {
  width: 100%;
}

/* LARGE PROFILE ADS */
.profile_ads img {
  height: 100%;
  width: 100%;
}

/* PROFILE STICKERS AND MESSAGES */
.stickersMessages {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile_sticker_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile_sticker_header h2 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.profile_sticker_header a {
  color: #4f0da3;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
}

.profile_sticker_box {
  padding: 10px;
  border-radius: 10px;
  background: #fff;

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  row-gap: 20px;

  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.profile_sticker_box img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

/* Messages */
.box_profile_message .box_profile_message_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 5px;
  background: #4f0da3;
}

.box_profile_message .box_profile_message_top h2 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.box_profile_message .box_profile_message_top > div {
  width: 30px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #fff;
  font-size: 134xpx;
}

.profile_chat_messages {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
  overflow-y: auto;
  height: 50vh;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.profile_chat_messages::-webkit-scrollbar {
  width: 0vw;
  height: 0vh;
}

.profile_chat_messages li:not(:nth-child(6)) {
  border-bottom: 1px solid #d5d5d5ca;
}

.profile_chat_messages .message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 8px 0;
}

.profile_chat_messages .message .left_content {
  display: flex;
  align-items: center;
  gap: 3px;
}

.profile_chat_messages .message .left_content img {
  max-width: none;

  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
}

.profile_chat_messages .left_content #cart {
  border-radius: 10px;
}

.profile_chat_messages .info {
  padding: 0 6px;
}

.profile_chat_messages .info h1 {
  color: #00000099;
  font-size: 12px;
  font-weight: 700;
}

.profile_chat_messages .info p {
  color: #00000099;
  font-size: 10px;
  font-weight: 300;
  margin: 0;
  text-align: left;
}

.profile_chat_messages .info .price {
  font-size: 10px;
  font-weight: 700;
  color: #00000099;
}

.profile_chat_messages .info .location {
  font-size: 9px;
  font-weight: 300;
  color: #00000099;
}

.profile_chat_messages .right_content {
  position: relative;
  height: 100%;
}

.profile_chat_messages .rating {
  display: flex;
  align-items: center;
}

.profile_chat_messages .right_content .active {
  width: 10px;
  height: 10px;
  background-color: #4f0da3;
  border-radius: 100%;

  position: absolute;
  bottom: 8px;
  right: 0;
}

.profile_chat_messages .cart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.profile_chat_messages .cart > div {
  font-size: 16px;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
}

.profile_chat_messages .right_content .trash {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
}

.profile_chat_messages .right_content .mail {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4f0da3;
  color: #ffff;
}

.profile_chat_messages .update div:first-child {
  font-size: 14px;
}

/* Profile Options */
.profileOptions {
  width: 298.281px;
  padding: 10px 15px 40px 15px;
  background-color: #fff;
  position: absolute;
  right: 0rem;
  top: -10px;

  display: flex;
  flex-direction: column;
  gap: 28px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border-radius: 3px;
  animation: profileOptions 0.5s ease-in-out;
}

@keyframes profileOptions {
  from {
    transform: translateY(-30px);
    opacity: 0.8;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.profileOptions_top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profileOptions_top button {
  align-self: flex-end;
}

.profileOptions_lists {
  padding: 0 !important;
}

.profileOptions_lists li {
  font-size: 16px;
  border-bottom: 1px solid #0000001a;
  padding: 10px 0;
  cursor: pointer;
  color: #000000;
}

.profileOptions_lists li a {
  text-decoration: none;
  color: #000000;
}

/* PROFILE OPTION FOR DESKTOP */
.profileOptionsMobile {
  display: none;
}

/* PROFILE OPTION REQUEST VERIFICATION MODAL */
.request_verification {
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.request_verification input:focus {
  background: #dadada71;
  transition: all 0.3s ease;
}

.request_verification_top h2 {
  font-size: 24px;
  font-weight: 500;
  color: #000000cc;
}

.request_verification p {
  font-size: 16px;
  font-weight: 400;
  color: #00000099;
  text-align: left;
}

.request_verification_middle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.request_verification_middle input {
  padding: 13px 15px;
  border: 1px solid #d7d7d775;
  border-radius: 4px;
  font-size: 14px;
}

.request_verification_bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.request_verification_bottom > p {
  font-size: 16px;
  font-weight: 400;
  color: #00000099;
  text-align: left;
}

.request_verification_bottom .link {
  width: 100%;
  border-bottom: 1px solid #d7d7d775;
  padding: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.request_verification_bottom .link input {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #00000099;
  border: none;
  border-radius: 0;
}

.request_verification_bottom .link button {
  width: 68.3px;
  height: 35px;
  border-radius: 5px;
  background: #4f0da3;

  font-size: 10px;
  font-weight: 300;
  color: #ffffff;
}

.request_verification_bottom .select_file {
  width: 300px;
}

.request_verification_bottom .select_file p {
  font-size: 12px;
  font-weight: 400;
  color: #000000cc;
}

.request_verification_bottom .select_file label {
  width: 141px;
  height: 34px;
  border-radius: 8px;
  background: #0000008a;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.select_file .camera {
  font-size: 24px;
  color: #ffff;
}

.request_verification_bottom_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CONFIRMATION MODAL */
.confirmation_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #a5a5a58d;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation {
  width: 320px;
  border-radius: 10px;
  background: #ffffff;
  padding: 1rem;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  gap: 10px;
}

.confirmation img {
  width: 62.03px;
  height: 62.03px;
  object-fit: contain;
}

.confirmation h1 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.confirmation p {
  font-size: 17px;
  font-weight: 300;
  text-align: center;
  margin: 0;
}

/* MEDIA QUERY FOR 1150PX */
@media screen and (max-width: 1150px) {
  .profile_top_content {
    padding: 0;
  }

  .cover_image {
    height: 150px;
  }

  .cover_image img {
    height: 100%;
    object-fit: cover;
  }

  .profile_details {
    padding-bottom: 3rem;
  }

  .profile_posts {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
  }

  .profileOptions {
    display: none;
  }

  /* PROFILE MOBILE STYLING */
  .profileOptionsMobile {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffff;
    width: 100%;
    height: 100vh;
    z-index: 200;

    padding: 27px 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    transition: all 0.5s ease-in-out;
    transform: translateX(-300rem);
  }

  .profileOptionsMobileActive {
    transform: translateX(0);
  }

  .profileOptionsMobile_top {
    display: flex;
    justify-content: space-between;
  }

  .profileOptionsMobile_top svg {
    width: 30px;
    height: 24px;
  }

  .profileOptionsMobile .middle {
    display: flex;
    flex-direction: column;
  }

  .profileOptionsMobile .middle .mid_user {
    display: flex;
    flex-direction: column;
    gap: 15px;

    margin-top: 6rem;
  }

  .profileOptionsMobile .middle .mid_user a {
    color: #000000cc;

    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
  }

  .profileOptionsMobile .middle .mid_user div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #d9d9d9;
    border-radius: 56px;
  }

  .profileOptionsMobile .middle .mid_user p {
    white-space: nowrap;
    width: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -10px;

    color: #000;
    font-size: 10px;
    font-weight: 400;
  }

  .profileOptionsMobile .bottom {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .profileOptionsMobile .bottom span {
    color: #e71515;

    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }

  .modal_btn {
    margin: 0;
  }

  .request_verification_bottom_button {
    padding: 2rem 0;
  }

  .profile_nav_left_btn .arrow_back {
    color: #eaeaea;
    font-size: 20px;
  }

  .profile_details_btns {
    gap: 8px;
  }

  .stickers {
    width: 100px;
    height: 40px;
  }

  .stickers span {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
  }

  .stickers span:last-child {
    font-weight: 800;
  }

  .request_verification_bottom_button {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 750px) {
  .user_profile_image {
    background: #ffffff;
    padding: 0;
  }

  .user_image_container {
    width: 100%;
    height: 500px;
    background-color: #eaeaea;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 4rem;
  }
  .user_image_container2 {
    width: 100%;
    height: 500px;
    background-color: #eaeaea;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 4rem;
  }

  .user_image_container img {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
  .user_profile_image {
    padding: 2rem 0rem;
  }

  .user_image_container2 img {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
  .user_profile_image {
    padding: 2rem 0rem;
  }

  .user_profile_image .cancel_btn {
    display: flex;
    margin: 3rem;
  }

  .user_profile_image .cancel_btn .arrow_back {
    width: 25.38px;
    height: 26px;
    color: #0b0b0bd8;
  }
  .user_profile_image2 .cancel_btn {
    display: flex;
    margin: 3rem;
  }

  .user_profile_image2 .cancel_btn .arrow_back {
    width: 25.38px;
    height: 26px;
    color: #0b0b0bd8;
  }

  .request_verification_top h2 {
    font-size: 15px;
  }

  .request_verification_top p {
    font-size: 14px;
  }

  .request_verification_bottom > p {
    font-size: 12px;
  }

  .modal_btn {
    width: 100%;

    margin-bottom: 0;
  }
}
