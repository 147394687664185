* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

.top {
  background-image: url('../../assets/image\ 5\ .png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 30%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-primary {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  padding: 0 35px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-bottom: 25px;
}
.heading-secondary {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.paragraph {
  margin-bottom: 30px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.action-btn{
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 16px;
}
.pruplr{
  background-color: #4F0DA3;
  color: white;
  border: none;
}
.white{
  background-color: white;
  color: #4b0289;
  border: 2px solid #000;
}
.cta{
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-container{
  background-color: #a786d1;
  padding: 60px;
}
.colored-text{
  text-align: center;
  color: #4b0289;
  font-size: 16px;
}
.heading-tertiary{
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}
.about-content {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
}


.board {
  width: 500px;
  height: 400px;
  background-color: #4b0289;
  border-radius: 20px;
}

.img {
  width: 500px;
  height: 400px;
}
.imag {
  width: 400px;
  height: 350px;
  margin-left: 40px;
}
.why {
  text-align: center;
  margin-top: 80px;
}

.about-text{
  font-size: 20px;
  color: #fff;
}


.cardGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: center;
  gap: 3rem;
  width: auto;
  align-items: center;
}


.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  margin-bottom: 1rem;
}

.features{
  background-color: #fff;
  padding-bottom: 30px;
}

.feature{
  padding: 0 50px;
}
.p-2 {
  padding: 0.5rem;
}


.mr-4 {
  margin-right: 1rem;
}


.boxCard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin: 50px;
}

.contact{
  background-color: #dbcfeb;
  padding: 30px;
}

.download{
  background-color: #4b0289;
  padding: 20px;
}

.download-button{
  display: flex;
  gap: 18px;
  align-items: center;
}

.header{
  font-size: 3.2rem;
  color: #fff;
  margin-bottom: 30px;
}

.download-text{
  font-size: 18px;
  color: #fff;
}

.border{
  background-color: transparent;
  color: white;
  border: 2px solid #000;
 }
 
 .footer {
   padding: 40px;
   display: flex;
   justify-content: space-around;
   align-items: center;
 }

 .link {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
  }

  .link a{
    margin-bottom: 10px;
    text-decoration: none;
    color: #000;
    font-size: 15px;
  }
  
  .subscribe-form {
    display: flex;
    align-items: center;
   justify-content: center;
   margin-top: 10px;
   background-color: transparent;
 }
 
 input {
   padding: 10px;
   margin-right: 5px;
   background-color: transparent;
   border: 2px solid #ccc;
   border-radius: 7px 0 0 7px;
  }
  
  .arrow {
    font-size: 20px;
    color: #fff;
    padding: 4px 7px;
    background-color: #4b0289;
    border-radius: 0  7px 7px 0;
  }


     .fdiv {
       display: flex;
       justify-content: space-between;
       align-items: center;
       gap: 300px;
     }
  


  @media (max-width: 1024px) {
    .top{
      padding:  10%;
    }
    .top-content{
      padding: 150px 0;
    }
    .heading-primary{
      font-size: 3rem;
      padding: 0;
    }
    .contact{
      padding: 30px 60px;
    }
  .heading-secondary{
    font-size: 2.6rem;
  }
  .paragraph{
    font-size: 1.6rem;
  }
  .action-btn{
    padding: 8px 12px;
    font-size: 16px;
  }
  .about-container{
    padding: 40px 20px;
  }
  .heading-tertiary{
    font-size: 24px;
  }
  
  .board{
    width: 250px;
    height: 250px;
  }
  .img{
    width: 250px;
    height: 250px;
  }
  .about-text-container{
    padding: 40px 10px 0 10px;
  }
  .features{
    padding: 0;
  }

  .footer{
    padding: 40px 10px;
  }
  .imag{
    display: none;
  }
  .boxCard{
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 10px;
    margin-top: 40px;
  }
 
  .fdiv{
    gap: 70px;
  }
  .download-button{
    display: grid;
  }
 
}

@media (max-width: 950px) {
     .hide {
       display: none;
     }
     .feature{
      padding: 10px;
     }
}

@media (max-width: 760px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .top {
    padding: 2%;
  }

  .top-content {
    padding: 150px 0px;
  }

  .heading-primary {
    font-size: 2.6rem;
    padding: 0;
  }

  .heading-secondary {
    font-size: 2rem;
  }

  .action-btn {
    padding: 6px 10px;
    font-size: 14px;
  }

  .about-container {
    padding: 40px 20px;
  }

  .heading-tertiary {
    font-size: 24px;
  }

  .about-content {
    display: block;
    padding: 20px 0;
  }

  .board {
    width: 250px;
    height: 250px;
  }

  .img {
    width: 250px;
    height: 250px;
  }

  .about-text-container {
    padding: 40px 10px 0 10px;
  }

  .features {
    padding: 0;
  }

  .imag {
    display: none;
  }

  .boxCard {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 10px;
  }

  .cardGrid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .why {
    margin: 40px 0;
  }

  .contact{
    padding: 30px 10px;
  }
  .footer {
    flex-direction: column;
    padding: 20px;
  }
  .fdiv{
    gap: 4px;
  }
}

@media(max-width: 500px){
  .top{
    padding: 0%;
  }
  .top-content{
    padding: 150px 0;
  }
 
}

@media(max-width: 400px) {
  .action-btn {
    padding: 6px 10px;
    font-size: 12px;
  }

  .top {
    padding: 0%;
  }

  .top-content {
    padding: 150px 0;
  }

 
}