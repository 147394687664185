.pic-cont-box {
  background: #fff;
  width: 90%;
  padding: 10px 0;
  border-radius: 10px;
  border: 1px solid #4f0da3;
}
.np-pic-bx {
  padding: 0 10px;
}

.com-icon-btn {
  background: transparent;
  height: fit-content;
  width: fit-content;
  padding: 0;
}

.com-icon-btn:disabled {
  background: transparent;
  height: fit-content;
  width: fit-content;
  padding: 0;
  pointer-events: none;
}

.inp-sen-send {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}

.pic-inpt {
  width: 90%;
  height: 24px;
  outline: none;
  border: none;
  caret-color: #4f0da3;
  font-size: 18px;
}

.np-pic-bx {
  position: relative;
  cursor: pointer;
  min-height: 50px;
}

.pic-cont-box img,
.pic-cont-box video {
  width: 100%;
  height: 120px;
  border-radius: 2px;
  object-fit: contain;
}

.delete-item {
  position: absolute;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  border-radius: 3.333px;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;
  gap: 5px;
  right: 5px;
  cursor: pointer;
  top: 5px;
  z-index: 111111;
}

@keyframes wave {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
  100% {
    transform: scaleY(1);
  }
}

.wave-bar {
  width: 4px;
  height: 100%;
  margin: 0 2px;
  background-color: currentColor;
}

.animate-wave {
  animation: wave 0.5s ease-in-out infinite;
}

.recording-indicator-container {
  display: flex;
  align-items: center;
}

.recording-indicator {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-right: 10px;
}

.wave {
  width: 4px;
  height: 10px;
  margin: 0 1px;
  background: #4f0da3;
  display: inline-block;
  animation: wave 1.2s infinite ease-in-out;
}

.audio-duration {
  margin-top: 8px;
  font-size: 14px;
  color: #4f0da3;
}

.custom-audio-player {
  display: flex;
  align-items: center;
}

.audio-duration {
  margin-left: 10px;
  font-size: 14px;
  color: #4f0da3;
}

@keyframes wave {
  0%,
  100% {
    height: 10px;
  }
  50% {
    height: 20px;
  }
}
