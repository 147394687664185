.view-ll {
  font-size: 14px;
  color: #ed6c30;
  margin-bottom: 20px;
  cursor: pointer;
}
.view-all-tic-bx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.even-cat-box {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 60px;
  width: 130px !important;
  padding-left: 10px;
}
.image-category-bx {
  border-radius: 50%;
  background: rgba(105, 0, 155, 0.1);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cat-name {
  font-size: 12px;
  font-weight: 600;
}
.tot-event {
  font-size: 11px;
  font-weight: 500;
  color: #5c5c5c;
}
.img-tick-cont {
  position: relative;
}
.img-tick-cont img {
  width: 70px;
  height: 70px;
  border-radius: 15px;
  object-fit: cover;
}
.event-date {
  font-size: 14px;
  color: #4f0da3;
  font-weight: 400;
}
.event-name {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
}
.location-box {
  font-size: 20px;
  color: #5c5c5c;
  display: flex;
  gap: 5px;
  align-items: center;
}
.loca-txt {
  font-size: 13px;
}
.tic-image-detail-bx {
  display: flex;
  gap: 10px;
  align-items: center;
}
.tick-full-card-container {
  margin: 15px 0;
}
.ver-lay img {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5%;
  top: 3%;
}
.fir-bt {
  border-radius: 5px;
  border: 1px solid #4f0da3;
  background: none;
  color: #4f0da3;
}

/* event-detail-style */
.event-details-box-cont {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.event-det-nm {
  font-size: 20px;
  font-weight: 500;
}
.chang {
  margin: 10px 0;
}
.title-lab {
  font-size: 18px;
  color: #5c5c5c;
}
.ticket-info-row-cal {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}
.ticket-info-boxs {
  padding: 15px;
  width: 50%;
  background: #fff;
  margin: 10px 5px;
}
.vip-re-cont {
  border: 1px solid rgba(105, 0, 155, 0.12);
  height: 200px;
  margin-top: 10px;
}
.pric-availl {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vip-price-bx {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  border-bottom: 1px solid rgba(105, 0, 155, 0.12);
  padding: 0 15px 10px;
  height: 50%;
  align-items: center;
}
.price-avil-box {
  font-size: 18px;
  font-weight: 600;
}
.out-stock {
  font-size: 16px;
  color: #ff4064;
}
.sellin {
  font-size: 16px;
  color: #48cf3e;
}
.no-bod {
  border-bottom: none;
}
.date-eve {
  font-size: 16px;
  font-weight: 500;
}
.eve-tim {
  font-size: 14px;
  color: #5c5c5c;
  margin: 5px 0;
}
.avail-ticket {
  width: 100px;
  font-size: 14px;
  color: #4f0da3;
  border-radius: 4px;
  border: 1px solid #69009b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 20px;
}
.padr {
  padding: 10px;
  height: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

/* receiver */
.receiver-detail-container {
  background: #fff;
  padding: 10px;
}
.rec-inp {
  height: 43px;
}
.check-bx {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  cursor: pointer;
}
.check-aree-cont {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.act-rec-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}
.reci-link {
  font-weight: 500;
  text-decoration: none;
}
.res-dw {
  margin-bottom: 10px;
}

/* search empty */
.notin-is {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.full-notin {
  font-size: 14px;
  width: 80%;
  text-align: center;
}
.empt-box-sea {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ticket-dash-card-cont {
  border-radius: 5px;
  background: #000;
  box-shadow: -1px 3px 11px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 50%;
  padding: 10px;
  cursor: pointer;
}
.all-sell-txt {
  font-size: 24px;
  font-weight: 500;
}
.sell-per-txt {
  font-size: 16px;
  color: #d0d5dd;
  margin: 8px 0;
}
.tot-evt-sold {
  font-size: 30px;
  font-weight: 400;
}
.sold {
  background: #003250;
}
.freepaid-bx {
  background: #fff;
  flex-direction: column;
  font-size: 16px;
  height: 100%;
  width: 30%;
  cursor: pointer;
  justify-content: center;
}
.free-paid-container {
  border-radius: 25px;
  border: 1px solid #e4e4e4;
  background: #fff;
  margin: 20px 0;
  justify-content: center;
  gap: 10px;
  height: 45px;
}
.act-tick {
  cursor: pointer;
  background: #e6d2ff;
}
.tick-cont-bxx {
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 10px;
  margin-bottom: 15px;
}
.red-bg {
  background: #ef0f0f;
  width: 80px;
}
.free-tc {
  padding-top: 5px;
  font-size: 14px;
}
.b-cr {
  background: rgba(235, 235, 235, 0.34);
}
.add-new-tck-bx {
  font-size: 16px;
  font-weight: 600;
  color: #4f0da3;
  cursor: pointer;
  text-align: right;
  margin-bottom: 10px;
}
.act-continue-btn {
  margin-top: 20px;
}
.nobd {
  font-size: 20px;
  color: #5c5c5c;
}
.noline {
  border: none;
  height: 100%;
  background: none;
}
.bac-formm {
  color: #4f0da3;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(220deg, #ffffff, #e8eaea);
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  transition: 0.4s;
  border-radius: 20px;
  background: #fff;
}

.switch input:checked + .slider {
  background: #76ee59;
}

.switch input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.add-info-cont {
  justify-content: space-between;
  margin-bottom: 10px;
}
.main-add-info {
  font-size: 14px;
  font-weight: 500;
}
.body-add-info {
  font-size: 12px;
  color: #5c5c5c;
  margin: 5px 0;
}
.add-info-line {
  border: 1px solid #e4e4e4;
  margin: 15px 0;
}
.bw-bit {
  margin-bottom: 10px;
}
.img-con-cv img {
  width: 100%;
  height: 370px;
  object-fit: cover;
}
@media only screen and (max-width: 1200px) {
  .title-lab {
    font-size: 13px;
  }
  .price-avil-box {
    font-size: 13px;
  }
  .out-stock {
    font-size: 13px;
  }
  .sellin {
    font-size: 13px;
  }
  .check-aree-cont {
    font-size: 14px;
  }
  .check-bx {
    height: 18px;
    width: 18px;
  }
  .ticccc {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1000px) {
  .ticccc {
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .ticket-info-row-cal {
    flex-direction: column;
  }
  .ticket-info-boxs {
    width: 90%;
  }
  .padr {
    height: 150px;
  }
  .vip-re-cont {
    height: 150px;
  }
  .check-aree-cont {
    font-size: 12px;
  }
  .empt-box-sea img {
    width: 200px;
  }
  .notin-is {
    font-size: 16px;
  }
  .full-notin {
    font-size: 12px;
  }
  .all-sell-txt {
    font-size: 18px;
  }
  .sell-per-txt {
    font-size: 13px;
  }
  .tot-evt-sold {
    font-size: 20px;
  }
}
@media only screen and (max-width: 430px) {
  .img-tick-cont img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
  .ver-lay img {
    width: 10px;
    height: 10px;
  }
}
@media only screen and (max-width: 370px) {
  .ticket-dash-card-cont {
    width: 100%;
  }
}
@media only screen and (max-width: 360px) {
  .event-date {
    font-size: 12px;
  }
  .event-name {
    font-size: 12px;
  }
  .location-box {
    font-size: 16px;
  }
  .loca-txt {
    font-size: 10px;
  }
  .redd {
    font-size: 13px;
  }
  .loc-red {
    font-size: 8px;
  }
  .redddd {
    width: 80px;
    height: 30px;
  }
  .rec-inp {
    font-size: 10px;
  }
  .freepaid-bx {
    font-size: 14px;
    width: 40%;
    cursor: pointer;
  }
}
