.post-menu-container button svg {
    color: #000000b9;
    font-size: 2.2rem;
}

.post-menu-dropdown ul li {
    font-size: 1.3rem !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    padding: 1rem !important;
}

.post-menu-dropdown ul li:last-child {
    color: rgb(218, 9, 9) !important;
}

.block-user-modal-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
}

.block-user-modal-btns button {
    background: transparent;
    font-size: 1.3rem;
    padding: .6rem 1.4rem;
    border-radius: .6rem;
    font-weight: 600;
}

.block-user-modal-btns button:hover,
.block-user-modal-btns button:focus,
.block-user-modal-btns button:active {
    background: rgba(0, 0, 0, 0.1);
}

.block-user-modal-btns button:nth-child(2) {
    color: rgb(218, 9, 9);
}

.block-user-modal-btns button:nth-child(2):hover,
.block-user-modal-btns button:nth-child(2):focus,
.block-user-modal-btns button:nth-child(2):active {
    background: rgba(218, 9, 9, 0.1);
}
.block-user-modal,
.promote-post-modal,
.report-user-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 360px;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    background: white;
    padding: 1rem 1.7rem;
}
.promote-post-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promote-post-modal img {
    width: 100%;
    height: 140px;
    object-fit: contain;
    margin-bottom: 1.2rem;
}

.promote-post-modal h3 {
    font-size: 1.5rem;
    font-weight: 800;
}
.promote-post-modal p {
    font-size: 12px;
}
.promote-post-modal .promote-post-cancel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: .6rem;
}

.promote-post-modal .promote-plan-options {
    display: flex;
    width: 100%;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 8px;
    margin-bottom: 1.5rem;
}

.promote-post-modal .promote-plan-options .promote-plan-grid,
.promote-plan-grid .plan-card {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    row-gap: 6px;
    column-gap: 16px;
}

.promote-plan-grid .plan-card {
    justify-items: flex-start;
    gap: 2px;
    border-radius: 4px;
    outline: 1px solid rgba(94, 94, 94, 0.822);
    padding: .4rem 0 .6rem 1rem;
    cursor: pointer;
}

.promote-plan-grid .plan-card input {
    cursor: pointer;
}

.promote-plan-grid .plan-card input[type='radio']:checked {
    accent-color: var(--primary-color);
}

.promote-plan-grid .plan-checked {
    outline: 1px solid var(--primary-color);
    cursor: pointer;
}

.promote-plan-grid .plan-card p {
    margin: 0;
    font-size: 11px;
}

.plan-card p:nth-child(3) {
    margin: 0;
    font-size: 14px;
    font-weight: bolder;
}

.promote-plan-grid .plan-card>span {
    margin: 0;
    background: var(--primary-color);
    color: hsl(266, 85%, 85%);
    padding: .145rem 1.1rem;
    border-radius: 4px 0 0 4px;
    width: 100%;
    font-size: 11px;
}

/* .promote-plan-btn {
    width: 100%;
}
.promote-plan-btn button {
    background: var(--primary-color);
    color: white;
    padding: 1rem 4rem;
    border-radius: .8rem;
    width: 100%;
} */

.report-user-modal form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.report-btn,
.promote-plan-btn {
    background: var(--primary-color);
    color: #fff;
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
    height: fit-content;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 6px;
}

.report-btn:disabled,
.promote-plan-btn:disabled {
    background: var(--primary-color-light);
    pointer-events: none;
    cursor: default;
}

@media screen and (max-width: 524px) {
    .promote-post-modal,
    .block-user-modal {
        width: 80%;
    }

    .promote-plan-grid .plan-card>span {
        padding: .145rem .3rem;
    }
}