/* :root {
    --white: #fff;
    --active-color: #00aeb0;
  } */
  
  /* * {
    box-sizing: border-box;
  } */
  
  /* html {
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
    background: var(--active-color);
    transition: background 0.4s ease;
  } */
  
  /* button {
    background: none;
    border: none;
    cursor: pointer;
  } */
  
  input[type="range"] {
    /* height: 1px; */
    -webkit-appearance: none;
    width: 100%;
    --range-progress: 0;
    /* margin-bottom: 10px; */
    border-radius: 8px;
    background: #3b7677;
    transition: background 0.2s ease;
    cursor: pointer;
  }

  input[type="range"]::before {
    /* ... */
    background: #f50;
    width: var(--range-progress);
    /* ... */
  }
  
  .audio-player {
    /* max-width: 350px; */
    /* padding: 24px; */
    /* border-radius: 20px; */
    /* box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2); */
    margin: auto;
    color: "black";
  }
  
  .artwork {
    border-radius: 120px;
    display: block;
    margin: auto;
    height: 200px;
    width: 200px;
  }
  
  .track-info {
    text-align: center;
    z-index: 1;
    position: relative;
  }
  
  .title {
    font-weight: 700;
    margin-bottom: 4px;
  }
  
  .artist {
    font-weight: 300;
    margin-top: 0;
  }
  
  .audio-controls {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: 0 auto 15px;
  }
  
  .audio-controls .prev svg,
  .audio-controls .next svg {
    width: 35px;
    height: 35px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .audio-controls .play svg,
  .audio-controls .pause svg {
    height: 40px;
    width: 40px;
    background: "white";
    border: none;
    cursor: pointer;
  }
  
  .audio-controls path {
    fill: var(--white);
  }
  
  .color-backdrop {
    background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%)
      no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  
  .color-backdrop.playing {
    animation: colorChange 20s alternate infinite;
  }
  
  @keyframes colorChange {
    from {
      filter: hue-rotate(0deg);
    }
    to {
      filter: hue-rotate(360deg);
    }
  }
  