.app_main {
  /* padding: 1rem 1.5rem; */
  overflow: auto;
  background-color: #f5f5f5;
}

.home-container {
  position: relative;
}

.modal-full-container {
  position: fixed;
  top: 0%;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  z-index: 111111111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-containe {
  display: flex;
  gap: 20px;
  position: relative;
  justify-content: space-between;
  /* width: 100%; */
 
}

.custom-tabs {
  display: flex !important;
  align-items: center !important;
  margin: 1.1rem 0 !important;
}

.MuiTabs-indicator {
  background: white !important;
}
.Mui-selected {
  padding: 0.8rem 0.6rem !important;
  margin: auto 0 !important;
  min-height: fit-content !important;
  height: fit-content !important;
  border-radius: 22px !important;
  background-color: var(--primary-color) !important;
  color: white !important;
}
.status-row {
  display: flex;
  width: 100%;
  gap: 10px;
  overflow: scroll;
  scrollbar-width: none;
}
.status-row::-webkit-scrollbar {
  width: 0.4vw;
}
.life-satus {
  color: #4f0da3;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.em-im {
  width: 60px;
  height: 60px;
  border: 3px solid #4f0da3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin-bottom: 5px;
}
.em-im img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.status-text {
  font-size: 9px;
  font-weight: 500;
}

.select-what-display {
  display: flex;
  background: #fff;

  padding: 10px 0;
 
  overflow-x: scroll;
  scrollbar-width: none;
  gap: 20px;
  align-items: center;
}
.select-what-display::-webkit-scrollbar {
  width: 0.4vw;
  height: 0.1vh;
}
.sel-act {
  background: #4f0da3;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.anot-wid {
  width: 80px;
  text-decoration: none;
  color: #403f3f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dis-sel-name {
  width: 70px;
  font-size: 12px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
}
.music-das-row {
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
  width: 100%;
}
.music-das-row::-webkit-scrollbar {
  width: 0.4vw;
}
.ticket-das-row {
  display: flex;
  gap: 10px;
  overflow: scroll;
  scrollbar-width: none;
}
.ticket-das-row::-webkit-scrollbar {
  width: 0.4vw;
}
.movie-das-row {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none;
  margin-top: 15px;
}
.movie-das-row::-webkit-scrollbar {
  width: 0.4vw;
}
.you-may-know {
  background: #fff;
  margin: 15px 0;
  padding: 10px;
}
@media only screen and (max-width: 1250px) {
  .dis-sel-name {
    width: 100px;
  }
}

@media only screen and (max-width: 1000px) {
  .main-containe {
    flex-direction: column;
    gap: 0;
    padding: 0;
  }

  .select-what-display {
    padding: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .em-im {
    width: 55px;
    height: 55px;
  }
  .status-text {
    width: 58px;
  }
}
@media only screen and (max-width: 574px) {
  .feeds-tabs-main-container {
    width: 95dvw !important;
  }
}
