.verify-container {
  background: url(../../../public/images/hero3.jpg);
  width: 100%;
  height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verify-box {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(17.5px);
  width: 80%;
  padding: 10em 0;
}
.be-condo {
  font-size: 20px;
  font-weight: 500;
}
.ins-tst-dig {
  font-size: 15px;
  margin: 5px 0;
}
.change-email {
  font-size: 15px;
  margin: 10px 0;
}
.change-email span {
  color: #ff8a15;
}
.ver-inp {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 10px;
  background: transparent;
  border: 1px solid #fff;
  outline: none;
  text-align: center;
  caret-color: #fff;
  color: #fff;
}
.verify-inputs input[type="number"]::-webkit-inner-spin-button,
.verinp input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.counter-resend {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin: 40px 0;
}
.verify-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verify-inputs {
  margin: 20px 0;
}
.veri-bttn-bx {
  display: flex;
  align-items: center;
  justify-content: center;
}
.resend {
  cursor: pointer;
}
.act-resend {
  color: #ff8a15;
  cursor: pointer;
}
.input-background-fill {
  background: #4f0da3;
  border: 1px solid #4f0da3;
}
.ver-uncop {
  border-radius: 30px;
  background: rgba(79, 13, 163, 0.5);
}
.complete-button {
  border-radius: 30px;
  background: #4f0da3;
}
.mv-to-up {
  /* margin-top: -80px; */
  align-items: flex-start;
  padding-top: 120px;
}
@media only screen and (max-width: 500px) {
  .verify-box {
    width: 90%;
    padding: 8em 0;
  }

  .ins-tst-dig {
    font-size: 13px;
    margin: 5px 0;
  }
}
@media only screen and (max-width: 350px) {
  .verify-box {
    width: 97%;
    padding: 8em 0;
  }
  .ins-tst-dig {
    font-size: 13px;
    margin: 5px;
    text-align: center;
  }
  .ver-inp {
    width: 40px;
    height: 40px;
  }
}
