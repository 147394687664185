.zigzag-border {
  position: relative;
}

.zigzag-border:before {
  content: "";
  position: absolute;
  top: -10px; 
  left: 0;
  right: 0;
  bottom: -10px; 
  background: inherit;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 15px,
    0 15px,
    0 30px,
    100% 30px,
    100% 45px,
    0 45px
  );
  z-index: -1;
}
