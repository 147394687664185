.form-wrapper {
  width: 100%;
  max-width: 646px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-field {
  margin-bottom: 16px;
}

label {
  display: block;
  font-size: 14px;
  margin-bottom: 6px;
  color: #333;
}

input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
}

.add-option {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #4f0da3;
  margin: 20px 0;
}

.add-option span {
  font-size: 16px;
}

.option-icon {
  width: 16px;
  height: 16px;
  background-color: #4f0da3;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.create-poll-btn {
  width: 211px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  background-color: #4f0da3;
  color: white;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 4px;
}


