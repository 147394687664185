* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.layout-container {
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

/* REWARD LAYOUT STYLING STARTS HERE */
.reward_container {
  width: 80%;
  height: 100%;
  background: #e6e6e6e1;
  position: absolute;

  padding: 110px 20px 0px 20px;

  display: flex;
  gap: 10px;
}

.reward_large_header {
  display: block;
}

.reward_small_header {
  display: none;
}

.reward_container .left {
  flex: 5;
  background-color: none;
}

.reward_container .right {
  flex: 3;
  background-color: #ffff;

  padding: 20px;
}

.how_to_earn_container {
  width: 437px;
  height: 275px;
  padding: 24px 9px 24px 9px;
  border-radius: 10px;
  background: #d0d5dd;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.how_to_earn_container h2 {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.how_to_earn_container .lists {
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.how_to_earn_container .lists li {
  height: 52px;
  padding: 10px 5px 10px 5px;

  border-radius: 5px;
  background: #ffffff;

  display: flex;
  align-items: center;
  gap: 10px;
}

.how_to_earn_container .lists li span:first-child {
  padding: 5px 8px 4px 9px;
  border-radius: 5px;

  background: linear-gradient(180deg, #ff8a15 0%, #ff8a1500 100%);
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 700;
}

.how_to_earn_container .lists li span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #403f3f;
}

/* REWARD LAYOUT STYLING ENDS HERE*/
.reward_main {
  height: 92%;
  padding: 0px 10px;

  overflow-y: auto;

  background-color: #ffff;
}
.layout-container {
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

/* .nav-cild {
    width: 85%;
  } */

.sidebar-boxxx {
  width: 100%;
  background: #4f0da3;
  height: 100vh;
}

.sidebar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
}

.side-items {
  margin: 0;
  padding: 50px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ctrl-btn {
  width: 40px;
  height: 40px;
  background: #4f0da3;
  position: fixed;
  font-size: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #fff;
  left: 15%;
  z-index: 3;
  top: 14%;
  display: none;
}
.side-item {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  color: #c47efb;
  gap: 10px;
  margin-bottom: 15px;
  text-decoration: none;
}
.side-icon {
  font-size: 23px;
}
.active_link {
  color: #fff;
}
li {
  /* list-style: none; */
}
.lout {
  margin-top: 10rem;
  cursor: pointer;
  color: #fff;
}

/* Navbar */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background: #fff;
  position: fixed;
  top: 0;
  width: 80%;
  z-index: 1;
  border-bottom: 1px solid rgb(238, 233, 233);
}
.logo-container {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 25px;
  font-weight: 800;
  color: #4f0da3;
}
.logo-container img {
  width: 60px;
}
.searc-profile {
  display: flex;
  align-items: center;
  gap: 15px;
}
.searc-container {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 35px;
  width: 400px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
}
.searc-inp {
  border: none;
  outline: none;
  width: 80%;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
}
.sea-icon {
  font-size: 20px;
  color: #0000009f;
}
.profile-container {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.profile-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.mobile-searc {
  display: none;
}
.notif-cont {
  position: relative;
  cursor: pointer;
}
.bell {
  font-size: 20px;
}
.bell-count {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #4f0da3;
  font-size: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
}
@media only screen and (max-width: 1150px) {
  .ctrl-btn {
    display: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    left: 23%;
    z-index: 111;
  }
  .lst {
    left: 0;
    top: 10%;
  }
  .lout {
    margin-top: 6rem;
    cursor: pointer;
    color: #fff;
  }

  .navbar-container {
    display: none;
  }

  .sidebar-boxxx {
    width: 0;
  }

  /* REWARD LAYOUT STYLING STARTS HERE */
  .reward_container {
    width: 100%;
    flex-direction: column;

    padding: 0;
  }

  .reward_modal_header {
    display: block;
  }

  .reward_container .left {
    background: none;
  }

  .reward_container .right {
    display: none;
  }

  .how_to_earn_container {
    width: 100%;
  }

  .reward_large_header {
    display: none;
  }

  .reward_small_header {
    display: block;
  }

  .how_to_earn_container .lists li span:first-child,
  .how_to_earn_container .lists li span:last-child {
    font-size: 10px;
  }

  .how_to_earn_container h2 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 940px) {
  .mobile-searc {
    display: initial;
  }
  .mobile-searc .sea-icon {
    font-size: 25px;
  }
  .searc-container {
    position: absolute;
    top: 100%;
    right: 10%;
    background: #fff;
  }
  .lst {
    left: 0;
    top: 10%;
  }
  .sidebar-box {
    left: -8%;
  }
}
@media only screen and (max-width: 750px) {
  .ctrl-btn {
    left: 28%;
  }
  .lst {
    left: 0;
  }
  .sidebar-box {
    left: -14%;
  }
}
@media only screen and (max-width: 700px) {
  .navbar-container {
    padding: 10px 50px;
  }
}
@media only screen and (max-height: 600px) {
  .side-item {
    margin-bottom: 10px;
  }
  .lout {
    margin-top: 3rem;
  }
}
@media only screen and (max-height: 500px) {
  .side-item {
    margin-bottom: 8px;
  }
  .lout {
    margin-top: 2rem;
  }
}
@media only screen and (max-height: 425px) {
  .side-item {
    margin-bottom: 4px;
  }
  .lout {
    margin-top: 0rem;
  }
}
@media only screen and (max-height: 355px) {
  .side-item {
    margin-bottom: 5px;
    font-size: 11px;
  }
  .side-icon {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .side-items {
    margin-left: 80px;
  }
  .ctrl-btn {
    left: 32%;
  }
  .lst {
    left: 0;
  }
}
@media only screen and (max-width: 500px) {
  .ctrl-btn {
    left: 37%;
  }
  .lst {
    left: 0;
  }
  .navbar-container {
    padding: 10px 30px;
  }
  .logo-container {
    font-size: 20px;
  }
  .logo-container img {
    width: 50px;
  }
  .profile-container img {
    width: 40px;
    height: 40px;
  }
  .searc-container {
    width: 75%;
  }
  .bell {
    font-size: 27px;
  }
}
@media only screen and (max-width: 420px) {
  .navbar-container {
    padding: 10px 20px;
  }

  .ctrl-btn {
    left: 45%;
  }
  .lst {
    left: 0;
  }
}
@media only screen and (max-width: 340px) {
  .ctrl-btn {
    left: 50%;
  }
  .lst {
    left: 0;
  }
}

@media only screen and (max-width: 300px) {
  .logo-container img {
    width: 30px;
  }
  .logo-text {
    font-size: 13px;
  }
  .mb-red {
    font-size: 12px;
  }
  .profile-container img {
    width: 30px;
    height: 30px;
  }
  .bell {
    font-size: 25px;
  }
}
