.cent {
  justify-content: center;
  gap: 5px;
}
.mv-bit {
  margin-top: 60px;
}
.pay-top-box {
  background: #4f0da3;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 20px auto 10px;
  position: relative;
}
.avail-bal {
  font-size: 20px;
}
.view-roy {
  font-size: 17px;
  text-decoration: underline;
  cursor: pointer;
}
.avail-bal-cont {
  justify-content: space-between;
}
.avail-tol-bal {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0;
}
.ifo-tvt {
  font-size: 14px;
}
.first-cir-pay {
  position: absolute;
  background: #f5f5f5;
  opacity: 0.2;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.25));
  bottom: -8%;
  right: -1%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.sec-cir-pay {
  position: absolute;
  background: #f5f5f5;
  opacity: 0.2;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  bottom: -10%;
  right: -2%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.curren {
  font-size: 20px;
  font-weight: 600;
}
.gad {
  gap: 10px;
}
.wit-inp {
  border-left: 1px solid #e4e4e4;
  padding-left: 10px;
}
.heawid-line {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.success-request-container {
  background: #fff;
  width: 40%;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
}
.success-request-container img {
  width: 150px;
}
.with-txt {
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}
.hist-mon-sel {
  border: 0.1px solid #fff;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0;
}
.with-cont {
  border-radius: 50%;
  background: #ed6c30;
  width: 40px;
  height: 40px;
  color: #fff;
  justify-content: center;
  font-size: 18px;
}
.with-bx {
  font-size: 14px;
  font-weight: 500;
}
.wit-dat {
  font-size: 11px;
  color: #5c5c5c;
  margin-top: 3px;
}
.left-col-roy {
  gap: 5px;
}
.neg-amt {
  font-size: 13px;
  color: #5c5c5c;
  font-weight: 500;
}
.succ-tct {
  font-size: 10px;
  color: #48cf3e;
}
.rit-side-col {
  flex-direction: column;
}
.colhistory-container {
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.row-with-hist-card {
  margin: 10px 0;
}

@media only screen and (max-width: 1000px) {
  .success-request-container {
    width: 70%;
  }
}
@media only screen and (max-width: 500px) {
  .avail-bal {
    font-size: 14px;
  }
  .view-roy {
    font-size: 12px;
  }
  .avail-tol-bal {
    font-weight: 500;
    font-size: 14px;
  }
  .success-request-container {
    width: 95%;
  }
  .ifo-tvt {
    font-size: 10px;
  }
}
