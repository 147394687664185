.image-show img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}
.con-gn {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #d0d5dd;
}
.wav-rec {
  background: #f5f5f5;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.vd-bx .rh5v-Overlay_inner {
  display: none;
}
.rh5v-Overlay_component {
  width: 0px;
  height: 80px;
  color: #fff;
}
.rh5v-Fullscreen_button {
  width: 34px;
  height: 3px;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
}
.vd-bx .rh5v-DefaultPlayer_video {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: contain;
}
.vd-bx {
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.icon-pl {
  color: #000;
  position: absolute;
  left: 40%;
  top: 40%;
  font-size: 25px;
}
.flie {
  font-size: 30px;
  color: #403f3f;
  cursor: pointer;
}
.looc {
  color: #0a75c3;
  font-size: 30px;
  cursor: pointer;
}
.mussi {
  font-size: 30px;
  cursor: pointer;
  color: #ff0000;
}
.mana-card-ad-container {
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f5f5f5;
  padding: 10px 15px;
  margin-bottom: 10px;
}
.ad-title {
  font-size: 14px;
  font-weight: 500;
  color: #4f0da3;
}
.ad-cat-txt {
  color: #171618;
  margin: 5px 0;
}
.expire-date {
  color: #0b0b0b;
}
@media only screen and (max-width: 300px) {
  .mana-card-ad-container {
    font-size: 10px;
    border-radius: 6px;
    padding: 10px;
  }
  .ad-title {
    font-size: 12px;
    color: #4f0da3;
  }
}
