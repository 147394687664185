.postFormModal-container {
  background: white;
  width: 50%;
  padding: 20px;
}
.post-clos-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-clos-box2 {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}
.cls-post {
  font-size: 20px;
  cursor: pointer;
}

.post-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: var(--primary-color);
  color: white;
  font-weight: normal;
}

.post-btn:disabled {
  background: var(--primary-color-light);
  color: hsl(0, 0%, 86%);
  font-style: italic;
  pointer-events: none;
  cursor: default;
  font-weight: normal;
}
.added-tag-tst > input {
  padding: 3px 8px;
}

.hashtags-container {
  border-radius: 3px;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px;
  height: 100%;
  margin-bottom: 15px;
  gap: 5px;
  flex-wrap: wrap;
  cursor: pointer;
}

.text-area {
  font-size: 1.8rem;
}
.add-tags-btn {
  max-width: 140px;
  background: #ff8a15;
  border-radius: 3px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 5px;
  font-size: 15px;
  cursor: pointer;
}

.added-tag-cont {
  justify-content: space-between;
  gap: 5px;
}
.cls-tag {
  color: #fff;
}

.add-tags-frd {
  font-size: 16px;
}
.tag-frd-cont {
  position: relative;
  width: 50px;
  height: 50px;
}
.tag-frd-cont img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.cls-tag-fr {
  position: absolute;
  font-size: 20px;
  right: 8%;
  top: -8%;
  z-index: 100;
  cursor: pointer;
}
.taged-frd-box {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
}
.post-img-cont-box,
.post-audio-cont-box {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 0px;
  overflow-x: scroll;
  scrollbar-width: none;
}
.viwdt {
  width: 100%;
}
.post-img-cont-box::-webkit-scrollbar {
  width: 0.4vw;
}

.im-vi-bxb img,
.file-item,
video {
  width: 160px;
  height: 100px;
  object-fit: cover;
}
.im-vi-bxb {
  position: relative;
  cursor: pointer;
}
.delete-item {
  position: absolute;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  border-radius: 3.333px;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;
  gap: 5px;
  right: 5px;
  cursor: pointer;
  top: 5px;
  z-index: 111111;
}
.delete-audio {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  border-radius: 3.333px;
  background: rgba(0, 0, 0, 0.5);
  gap: 5px;
  width: 70px;
  height: 30px;
  padding: 0 5px;
  justify-content: center;
  cursor: pointer;
}
.de-aud {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.file-content {
  position: relative;
  background: #f5f5f5;
  margin-bottom: 5px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.6rem;
  width: 140px;
}

.file-content-name {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  width: 80%;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-file-item {
  position: absolute;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  border-radius: 3.333px;
  background: rgba(0, 0, 0, 0.5);
  padding: 4px;
  right: 4px;
  cursor: pointer;
  top: 4px;
  z-index: 111111;
}

.get-location-button {
  margin-bottom: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  background: var(--primary-color);
  color: white;
}

.word-doc-item,
.excel-doc-item,
.location-item {
  width: 100%;
}

.location-container {
  position: relative;
}

.location-address {
  position: absolute;
  border-radius: 4px;
  padding: 3px 6px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: 8px;
  margin-bottom: 4px;
  z-index: 5000;
  bottom: 0;
  left: 0;
  font-size: 12px;
  color: white;
}
.dra-im {
  position: relative;
  width: 100%;
  background: #f5f5f5;
  height: 150px;
  margin-bottom: 5px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.add-vid {
  font-size: 18px;
  margin: 5px 0;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.or-dr {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
}

.add-new-pic-or {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(0, 0, 0, 0.7);
  margin: 2px 0 8px;
  cursor: pointer;
  justify-content: flex-end;
}
.add-nnew {
  font-size: 12px;
}

.icon-dw {
  margin-bottom: 6px;
  font-size: 18px;
}

.rec-bbtn {
  width: 100px;
  display: flex;
  border: none;
  background: #ff1050;
  color: #fff;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 30px;
  margin: 10px 0;
  border-radius: 10px;
  gap: 10px;
}

.active {
  background-color: white;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .postFormModal-container {
    width: 70%;
  }
}
@media only screen and (max-width: 700px) {
  .postFormModal-container {
    width: 90%;
    padding: 10px;
  }
}

@keyframes wave {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
  100% {
    transform: scaleY(1);
  }
}

.recording-indicator-container {
  display: flex;
  align-items: center;
}

.recording-indicator {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-right: 10px;
}

.wave {
  width: 4px;
  height: 10px;
  margin: 0 1px;
  background: #4f0da3;
  display: inline-block;
  animation: wave 1.2s infinite ease-in-out;
}

@keyframes wave {
  0%,
  100% {
    height: 10px;
  }
  50% {
    height: 20px;
  }
}
