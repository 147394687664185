.buss-all-container {
  width: 100%;
}
.bus-dir {
  font-weight: 700;
}
.busss {
  font-size: 16px;
}

@media only screen and (max-width: 1150px) {
  .bus-box-con {
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  .bus-box-con {
    width: 100%;
  }
  .buss-all-container {
    margin-top: -40px;
  }
  .bus-dir {
    /* margin-left: 20px; */
    font-size: 18px;
  }
}
