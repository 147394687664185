.business_analyses {
  width: 337px;
  height: 58.22px;
  border-radius: 5px;
  background: #4f0da3;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 2rem;
  margin-top: 1rem;
}

.business_analyses > div {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  gap: 2px;
}

.business_analyses span {
  font-size: 13px;
  font-weight: 300;
  color: #ffffff;
}

.business_analyses h2 {
  font-size: 15px;
  font-weight: 700;
}

/* BUSINESS POSTS */
.business_posts_container {
  padding: 2rem 0;
}

.business_posts_container h1 {
  font-size: 16px;
  font-weight: 700;
}

.business_posts_container .posts {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;

  display: flex;
  gap: 10px;
}

.business_posts_container .posts::-webkit-scrollbar {
  width: 0vw;
  height: 0vh;
}

.business_posts_container_post {
  border-radius: 10px;
  border: 3px solid #c3c3c366;

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px;
  color: #100e0e99;
}

.business_posts_container_post_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
  width: 200px;
}

.business_posts_container .createdBy {
  font-size: 12px;
  font-weight: 400;
}

.business_posts_container .time {
  font-size: 13px;
  font-weight: 400;
}

.business_posts_container_post_middle {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.business_posts_container_post_middle img {
  width: 100%;
  height: 100%;
  border-radius: 10px;

  object-fit: cover;
}

.business_posts_container_post h1 {
  font-size: 15px;
  font-weight: 500;
  color: #100e0e;
  padding: 4px 0;
}

.business_posts_container_post_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #100e0e99;
}

.business_posts_container_post_bottom .ratings {
  font-size: 13px;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 4px;
}

.business_posts_container_post_bottom .ratings .star {
  width: 14.44px;
  height: 13.92px;

  color: #ff8a15;
}

.business_posts_container_post_bottom .price {
  font-size: 16px !important;
  font-weight: 500;
  color: #100e0e99;
}

.manage_store_btn {
  width: 100%;

  display: flex;
  justify-content: center;

  padding: 4rem 0;
}

/* BUSINESS PROFILE MODAL */
/* .business_availability {
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 3rem 0;
} */

/* .business_availability h1 {
  font-size: 17px;
  font-weight: 500;
  color: #000000cc;
} */

/* .business_availability p {
  font-size: 13px;
  font-weight: 400;
  color: #00000099;
} */

/* .business_availability .availability {
  display: flex;
  flex-direction: column;
  gap: 10px;
} */

/* .react_switch {
  border: 1px solid #d0d0d091;
} */

/* .business_availability .uncheckedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 13px;
  color: #898989;
} */

/* .business_availability .uncheckedHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 100%;

  background: #cfcfcfdf;
} */

/* .business_availability .checkedHandleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #ffff;
  border-radius: 100%;
} */

/* .business_availability .checkedText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 13px;
  padding-right: 2;
  color: #ffffff;
} */

/* .business_availability .availability .available {
  display: flex;
  align-items: center;
  gap: 10px;
} */

/* .always_available {
  display: flex;
  align-items: center;
  gap: 5px;

  font-size: 13px;
  font-weight: 400;
  color: #00000099;
} */

/* .final_touch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 20px;
} */

/* .final_touch .final_touch_mark {
  font-size: 19px;
} */

/* .final_touch p {
  height: 40px;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 6px;

  background: #f2e9fa;
  border-radius: 3px;

  font-size: 12px;
  font-weight: 500;
  color: #11132b;
} */

@media screen and (max-width: 1150px) {
  .business_profile_container {
    height: 92vh;
  }

  .business_posts_container,
  .manage_store_btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .business_posts_container .posts {
    max-width: none;
  }

  .business_availability {
    padding: 2rem 0;
  }

  .edit_business_profile_content .top_content,
  .edit_business_profile_content .bottom_content {
    flex-direction: column;
  }

  .title_not_needed > label {
    display: none;
  }

  .business_analyses {
    width: 98%;
    height: 58px;
    gap: 5px;
    padding: 1rem;
  }

  .business_analyses span {
    font-size: 11px;
  }

  .business_analyses h2 {
    font-size: 11px;
  }
}
