
.form-header .third-stepIndicator1::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 15px;
    height: 15px;
    background-color: #4f0da3;
    border-radius: 50%;
    border: 3px solid #4f0da3;
}


.form-header .third-stepIndicator1.active::before {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator1.finish::before {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator1::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #4f0da3;
}
.form-header .third-stepIndicator1.active::after {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator1.finish::after {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator1:last-child:after {
    display: none;
}

/*  */



.form-header .third-stepIndicator2::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-63%);
    z-index: 9;
    width: 15px;
    height: 15px;
    background-color: #4f0da3;
    border-radius: 50%;
    border: 3px solid #4f0da3;
}


.form-header .third-stepIndicator2.active::before {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator2.finish::before {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator2::after {
    content: "";
    position: absolute;
    left: 53%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #4f0da3;
}
.form-header .third-stepIndicator2.active::after {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator2.finish::after {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator2:last-child:after {
    display: none;
}

/*  */
.form-header .third-stepIndicator3::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 15px;
    height: 15px;
    background-color: #4f0da3;
    border-radius: 50%;
    border: 3px solid #4f0da3;
}
.form-header .third-stepIndicator3.active::before {
    background-color: transparent;
}
.form-header .third-stepIndicator3.finish::before {
    background-color: transparent;
}
.form-header .third-stepIndicator3::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #4f0da3;
}
.form-header .third-stepIndicator3.active::after {
    background-color: #4f0da3;
}
.form-header .third-stepIndicator3.finish::after {
    background-color: transparent;
}
.form-header .third-stepIndicator3:last-child:after {
    display: none;
}
