.feed-detail-container {
  background: white;
}

.quick-dial-container {
  position: fixed;
  top: 44dvh;
  right: 40%;
}

.quick-dial-wrapper button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1.3rem;
  width: 40px !important;
  height: 40px !important;
}
.quick-dial-wrapper > button:nth-child(1) {
  background: var(--primary-color);
}

.feed-top {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.post-display-images img,
.post-display-images video {
  /* width: 100%;
    margin-bottom: .8rem;
    height: auto;
    object-fit: cover; */
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.post-display-images audio {
  width: 100%;
  margin-bottom: 0.8rem;
}

.post-display-images {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  gap: 10px;
  margin: 20px 0;
}

.post-display-images audio::-webkit-media-controls-enclosure {
  border-radius: 0;
}

.post-display-content p {
  text-align: left !important;
}

.inpu-com-box {
  position: fixed;
  bottom: 18vh;
  width: 42%;
  margin-left: 40px;
}

.pic-bx-cont {
  bottom: 12vh;
  margin-left: 32px;
}

@media only screen and (max-width: 1200px) {
  .inpu-com-box {
    /* bottom: 12vh; */
    width: 38%;
    margin-left: 60px;
  }
  .pic-bx-cont {
    bottom: 12vh;
    margin-left: 40px;
  }
}

@media screen and (max-width: 1150px) {
  .quick-dial-container {
    right: 40%;
  }

  .inpu-com-box {
    bottom: 15vh;
    width: 42%;
    margin-left: 150px;
  }

  .pic-bx-cont {
    /* bottom: 20vh; */
    left: 0;
  }
}

@media screen and (max-width: 1000px) {
  .quick-dial-container {
    right: 6%;
  }

  .inpu-com-box {
    right: 8%;
  }

  .pic-bx-cont {
    /* bottom: 20vh; */
    left: 5%;
    width: 70%;
  }
}

@media only screen and (max-width: 830px) {
  .inpu-com-box {
    right: 0%;
    width: 80%;
  }
  .pic-bx-cont {
    /* bottom: 20vh; */
    left: 5%;
    width: 70%;
  }
}
@media only screen and (max-width: 700px) {
  .pic-bx-cont {
    /* bottom: 40vh; */
    right: 0%;
    width: 80%;
    margin-left: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .inpu-com-box {
    /* bottom: 26vh; */
    width: 90%;
  }
  .pic-bx-cont {
    /* bottom: 12vh; */
    left: 5%;
    width: 70%;
  }
}
@media only screen and (max-width: 380px) {
  .inpu-com-box {
    right: 5%;
    width: 95%;
  }
  .pic-bx-cont {
    /* bottom: 20vh; */
    left: 5%;
    width: 80%;
  }
}
