.business-search-card {
  border-radius: 15px;
  border: 3px solid #4f0da3;
  background: #ff8a15;
  padding: 10px;
  margin-bottom: 15px;
}
.business-logo-con {
  display: flex;
  align-items: center;
  color: #161313;
  gap: 10px;
}
.business-logo-con img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}
.buss-nm {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}
.buss-loc {
  font-size: 12px;
}
.buss-desc {
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0;
}
.touch-with-bx {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.touch-btn {
  background: #fff;
  color: #4f0da3;
  border: none;
  height: 35px;
  width: 32%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  gap: 5px;
  cursor: pointer;
}

.touch-btn-txt {
  font-weight: 500;
}

.claim-visit-page-btn {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}
.claim-visit-btn {
  width: 50%;
  border: none;
  border-radius: 5px;
  /* background: #161313; */
  color: #f5f5f5;
  height: 30px;
  cursor: not-allowed;
}
.visi-pae {
  background: #4f0da3;
}
.company-time-cont {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.logo-company-detail {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.brief {
  font-size: 25px;
}
.position-txt {
  font-size: 18px;
  font-weight: 500;
}
.office-txt {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0;
}
.office-loc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.salary-job {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  margin: 5px 0;
}
.time-posted-cur {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
}
.viewa {
  border-radius: 10px;
  border: 1px solid #4f0da3;
  background: #f5f5f5;
  color: #161313;
  margin-top: 10px;
  height: 35px;
}
.messend {
  border-radius: 10px;
  border: 1px solid #4f0da3;
  background: #4f0da3;
  color: #f5f5f5;
  height: 35px;
  margin-top: 10px;
}
.job-search-container {
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
}
.sift {
  padding: 0 30px;
}
@media only screen and (max-width: 500px) {
  .buss-nm {
    font-size: 12px;
  }
  .buss-loc {
    font-size: 10px;
  }
  .buss-desc {
    font-size: 12px;
  }
}
