/* REWARD HEADER */
/* .rewards {
  max-height: 100%;

  background-color: #ffff;
} */

.reward_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
}

.reward_header .left {
  display: flex;
  align-items: center;
  gap: 25px;
}

.reward_header svg {
  width: 24px;
  height: 24px;
}

.reward_header a {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.reward_header span {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

/* REWARD MAIN */
.reward_main {
  height: 80vh;
  padding: 1rem 10px;

  overflow-y: auto;
}

.reward_main::-webkit-scrollbar {
  width: 0vh;
}

.reward_balance_box {
  width: 100%;
  height: 171px;
  padding: 20px;

  border-radius: 10px;
  background: #4f0da3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.reward_balance_box_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.reward_balance_box_top h5 {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.reward_balance_box_top a {
  display: flex;
  align-items: center;
  grid-area: 10px;

  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

.reward_balance_box_top_arrow {
  font-size: 20px;
  color: #ffff;
}

.reward_balance_box_middle {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 3px;
}

.reward_balance_box_middle img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.reward_balance_box_middle .balance {
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
}

.reward_balance_box_middle .show {
  margin-left: 1rem;
}

.reward_balance_box_middle .show .eye {
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}

.reward_balance_box > a {
  width: 189px;
  height: 34px;
  padding: 10px 40px 10px 40px;
  border-radius: 5px;

  font-size: 12px;
  font-weight: 400;
  color: #4f0da3;
  background: #ffffff;
  text-decoration: none;
}

/* REWARD ITEMS */
.reward_items {
  width: 100%;
  padding-top: 2rem;
}

.reward_items_header {
  display: flex;
  justify-content: space-between;
}

.reward_items_header h2 {
  font-size: 16px;
  font-weight: 500;
}

.reward_items_header button {
  font-size: 12px;
  font-weight: 400;
  color: #4f0da3;
  cursor: pointer;
}

.reward_items_lists {
  display: flex;
  flex-direction: column;
  gap: 14px;

  padding: 1rem 0;
  margin: 0;
}

.reward_items_lists li {
  padding: 18px 10px;
  border-radius: 10px;
  background: #f5f5f5;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reward_items_lists li .reward_left {
  display: flex;
  align-items: center;
  gap: 17px;
}

.reward_items_lists li .reward_left .icon {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  background: #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
}

.reward_items_lists li .reward_left .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;

  height: 100%;
}

.reward_items_lists li .reward_left .icon img {
  width: 16px;
  height: 16px;
}

.reward_items_lists li .reward_left .text h1 {
  font-size: 14px;
  font-weight: 500;

  display: flex;
  align-items: center;
}

.reward_items_lists li .reward_left .text > div {
  font-size: 12px;
  font-weight: 400;
}

.reward_items_lists li .reward_left .text span {
  font-size: 10px;
  font-weight: 400;
  color: #d2b977;

  display: flex;
  align-items: center;
}

.reward_items_lists li button {
  background: none;
  border-radius: 5px;
  background: #4f0da3;

  width: 66px;
  height: 21px;
  border-radius: 5px;

  font-size: 10px;
  font-weight: 400;
  color: #ffff;
}

.other_lists_type {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.other_lists_type > div:first-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress_wrapper {
  width: 100%;
  height: 6px;
  border-radius: 20px;
  background: #d9d9d9;

  margin-top: 13px;
}

.progress_wrapper .progress {
  width: 60%;
  height: 6px;
  border-radius: 20px;

  background: #4f0da3;
}

.other_lists_type > div:last-child {
  align-self: flex-end;
  margin-top: 9px;
  font-size: 10px;
}

/*






*/

.reward_refer_box {
  width: 100%;
  /* height: 227px; */
  border-radius: 10px;
  background-color: #b469ef;
  padding: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.reward_refer_box_top {
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.reward_refer_box_top_left h1 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.reward_refer_box_top_left p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

.reward_refer_box_top_right {
  width: 60%;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reward_refer_box_top_right .register_type {
  width: 100%;
  border: 1px solid #fcfcfc82;
  border-radius: 4px;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 5px;
}

.reward_refer_box_top_right .register_type input {
  width: 100%;
  height: 30px;
  padding: 0 5px;

  border: none;
  outline: none;
  font-size: 14px;
  color: #ffff;

  background: none;
}

.reward_refer_box_top_right .register_type input::placeholder {
  color: #ffff;
  font-size: 18px;
  font-weight: 400;
}

.reward_refer_box_top_right .register_type button {
  height: 30px;

  padding: 0 25px;
  border-radius: 5px;

  font-size: 12px;
  color: #4f0da3;

  text-align: center;
  background-color: #ffff;
}

.reward_refer_box_top_right > button {
  font-size: 12px;
  font-weight: 400;
  color: #ffff;
}

.reward_refer_box_bottom {
  width: 230px;
  height: 40px;
  padding: 12px 33px;
  border-radius: 5px;
  background-color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* HISTORY */
/* .histories {
  overflow-y: auto;
  height: 92%;

  padding: 30px;
} */

.reward_main h3 {
  font-size: 16px;
  font-weight: 700;

  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.histories_for_the_month {
  margin: 0;
  padding: 0;
}

.reward_main::-webkit-scrollbar {
  width: 0vw;
}

.history {
  padding: 1.2rem 0;
  border-bottom: 1px solid #e4e4e4;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history .history_left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.history .history_left .logo {
  background: green;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  width: 35px;
  height: 35px;
}

.history .history_left .logo img {
  object-fit: cover;
}

.history .history_left .history_type h2 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.history .history_left .history_type p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;
  color: #5c5c5c;
}

.history_right {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.history_right > div:first-child {
  align-self: flex-end;

  display: flex;
  gap: 3px;
  font-size: 14px;
  font-weight: 500;
}

.history_right img {
  width: 20px;
  height: 20px;
}

.history_right div {
  font-size: 12px;
  font-weight: 400;
}

/* PAYOUTS */
.payouts {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 76.3vh;

  margin-top: 2rem;
}

.payout_container {
  width: 428px;
  height: 60vh;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  padding: 2rem 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.payout_container_top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payout_container_top h1 {
  font-size: 64px;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 10px;
}

.payout_container_top h1 span {
  width: 30px;
  height: 21px;
  padding: 5px;
  gap: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  font-size: 10px;
  font-weight: 400;
}

.payout_container_top p {
  display: flex;
  gap: 10px;

  font-size: 12px;
  font-weight: 400;
  color: #403f3f;
  margin-left: -2rem;
}

.payout_container_top p div {
  display: flex;
  align-items: center;
  gap: 2px;
}

.payout_container_top p div > span {
  font-size: 14px;
  font-weight: 500;
  color: #5c5c5c;
}

.payout_container .middle {
  display: flex;
  align-items: center;

  width: 100%;
}

.payout_container .middle div:first-child {
  width: 43px;
  height: 26px;
  padding: 8px 13px 8px 16px;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #d0d5dd;
  border-left: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.payout_container .middle div:last-child {
  height: 1px;
  width: 100%;
  background: #d0d5dd;
}

.payout_container .bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 10px;
}

.payout_container .bottom p {
  font-size: 12px;
  font-weight: 400;
}

.payout_container .bottom .rates {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payout_container .bottom .rates div:first-child {
  font-size: 12px;
  font-weight: 400;
}

.payout_container .bottom .rates div:last-child {
  font-size: 20px;
  font-weight: 400;
}

.payout_container .bottom .cta {
  margin-top: 8rem;
}

/* PAYMENT CONTAINER */
.payment_container {
  width: 100%;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment_container_top {
  width: 100%;
  height: 140px;
  border-radius: 10px;
  background-color: #f5f5f5;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.payment_container_top_row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 247px;
  padding: 10px;
  border-radius: 5px;

  background-color: #ffff;
}

.payment_container_top_row p {
  font-size: 13px;
  font-weight: 200;
  margin: 0;
}

.payment_container_top_row .coin_earned {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.payment_container_top_row h4 {
  margin: 0;
}

.payment_container_bottom {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment_container_bottom h2 {
  font-size: 16px;
  font-weight: 500;
}

.payment_input {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment_input input {
  border: 2px solid #e5e5e579;
}

.payment_input input:focus {
  background: #dadada71;
  transition: all 0.3s ease;
}

/* PAYOUT MODAL */
.payout_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f53e;

  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  z-index: 99999999;
}

.payout_modal .confirmation {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.payout_modal img {
  width: 102.86px;
  height: 129.86px;
}

@media screen and (max-width: 1150px) {
  .rewards {
    max-height: none;

    background-color: #ffff;
  }

  .reward_main {
    height: 92vh;

    padding-top: 2rem;
  }

  .reward_balance_box {
    padding: 10px;
    height: 130px;
  }

  .reward_balance_box_top h5 {
    font-size: 13px;
  }

  .reward_balance_box_top a {
    text-decoration: underline;
    font-size: 11px;
  }

  .reward_balance_box_top_arrow {
    font-size: 13px;
  }

  .reward_balance_box_middle img {
    width: 25px;
    height: 25px;
  }

  .reward_balance_box_middle .balance {
    font-size: 25px;
    font-weight: 400;
    color: #ffffff;
  }

  .reward_balance_box_middle .show {
    margin-left: 1rem;
  }

  .reward_balance_box_middle .show .eye {
    font-size: 15px;
  }

  .reward_balance_box > a {
    width: 190px;
    font-size: 10px;
  }

  .reward_items {
    padding-top: 2rem;
  }

  .reward_items_lists {
    gap: 10px;

    padding: 0.6rem 0;
  }

  .reward_items_lists li {
    padding: 10px;
  }

  .reward_items_lists li .reward_left {
    gap: 10px;
  }

  .reward_items_lists li .reward_left .text h1 {
    font-size: 12px;
  }

  .reward_items_lists li .reward_left .text > div {
    font-size: 10px;
  }

  .other_lists_type {
    gap: 0px;
  }

  .progress_wrapper {
    height: 6px;
  }

  .progress_wrapper .progress {
    height: 6px;
  }

  .reward_refer_box {
    margin-top: 1rem;
    padding: 10px;
  }

  .reward_refer_box_top {
    display: flex;
    flex-direction: column;
  }

  .reward_refer_box_top_left p {
    text-align: left;
    margin-top: -5px;
  }

  .reward_refer_box_top_right {
    width: 100%;
  }

  .reward_refer_box_top_right .register_type input::placeholder {
    font-size: 14px;
  }

  /* PAYOUTS */
  .payouts {
    position: fixed;
    margin-top: 0rem;

    background-color: #ffff;
    height: 100%;

    justify-content: flex-start;
    align-items: flex-start;
  }

  .payout_container {
    width: 100%;
    border: 0px solid #d9d9d9;
    padding: 0px;
  }

  .payout_container_top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payout_container_top h1 {
    font-size: 64px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  .payout_container_top h1 span {
    width: 30px;
    height: 21px;
    padding: 5px;
    gap: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    font-size: 10px;
    font-weight: 400;
  }

  .payout_container_top p {
    display: flex;
    gap: 10px;

    font-size: 12px;
    font-weight: 400;
    color: #403f3f;
    margin-left: -5rem;
  }

  .payout_container_top p div {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .payout_container_top p div > span {
    font-size: 14px;
    font-weight: 500;
    color: #5c5c5c;
  }

  .payout_container .middle {
    display: flex;
    align-items: center;

    width: 100%;
  }

  .payout_container .middle div:first-child {
    width: 43px;
    height: 26px;
    padding: 8px 13px 8px 16px;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #d0d5dd;
    border-left: none;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .payout_container .middle div:last-child {
    height: 1px;
    width: 100%;
    background: #d0d5dd;
  }

  .payout_container .bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 10px;
  }

  .payout_container .bottom p {
    font-size: 12px;
    font-weight: 400;
  }

  .payout_container .bottom .rates {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .payout_container .bottom .rates div:first-child {
    font-size: 12px;
    font-weight: 400;
  }

  .payout_container .bottom .rates div:last-child {
    font-size: 20px;
    font-weight: 400;
  }

  .payout_container .bottom .cta {
    width: 90%;
    margin-top: 8rem;
  }

  /* PAYMENT CONTAINER */
  .payment_container {
    padding: 15px 20px;
  }
}
