.forgot-boxx {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10em 0;
}
.for-pass-comp-cont {
  width: 40%;
}
.key-cont {
  width: 80px;
  height: 80px;
  background: rgba(79, 13, 163, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  margin: 50px auto 25px;
}

.for-head-mai {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
  text-align: center;
}
.ash {
  border-radius: 30px;
  background: #f5f5f5;
  color: #000;
}
.upp {
  margin-top: -1px;
}
@media only screen and (max-width: 1200px) {
  .for-pass-comp-cont {
    width: 60%;
  }
}
@media only screen and (max-width: 600px) {
  .for-pass-comp-cont {
    padding: 0 10px;
    width: 90%;
  }
  .forgot-boxx {
    padding: 5em 0;
  }
}
