.buss-dir-right {
  background: #fff;
  padding: 10px;
}
.business-search-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  position: relative;
}
.search-container-busi {
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid var(--stroke, #e4e4e4);
  backdrop-filter: blur(2.5px);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 45px;
  padding: 20px 10px;
  color: #5c5c5c;
  background: #fff;
}

.search-inp-bus {
  width: 100%;
  outline: none;
  border: none;
}

.filter-search-bus {
  font-size: 25px;
  color: #5c5c5c;
  border: 1px solid #e4e4e4;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.bus-loggo img {
  width: 60px;
  height: 55px;
  border-radius: 7px;
  background: #f5f5f5;
  object-fit: cover;
}
.busin-small-card-container {
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #4f0da3;
  background: #ff8a15;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
  margin-top: 10px;
}
@media only screen and (max-width: 940px) {
  .search-container-busi {
    font-size: 15px;
    height: 35px;
  }
}
